<template>
  <div>
    <subMenu
      key="subMenu"
      :activeTab="activeTab"
      :menuList="menuList"
      @handItem="handItem"
    ></subMenu>

    <div class="main-box">
      <div class="header-title">数字建筑平台应用系统</div>
      <div class="nav">
        <div
          class="nav-item"
          :class="isActive == index && 'is_active'"
          v-for="(item, index) in leftMenuList"
          :key="index"
          @click="handItem(item, index)"
        >
          <div>
            <el-image
              style="width: 40px; height: 40px"
              :src="isActive == index ? activeIconList[index] : item.logo"
            ></el-image>
          </div>
          <div>{{ item.name }}</div>
        </div>
      </div>

      <div class="right-content">
        <div
          class="child-item"
          v-on:mouseover="handleMouseOver(index)"
          v-on:mouseout="handleMouseOut(index)"
          v-for="(item, index) in rightList"
          :key="index"
        >
          <div class="top-tip mb-30"></div>
          <div class="fz-24">{{ item.name }}</div>
          <div class="b-line"></div>
          <div class="a-line1 a-mb60">{{ item.label }}</div>
          <div>
            <a
              :href="item.path"
              target="_blank"
              class="fc"
              :class="childIndex == index ? 'primarys' : ''"
            >
              <el-button
                size="mini"
                >查看详情
                <i class="el-icon-arrow-right el-icon--right"></i></el-button
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import subMenu from "@/components/sub-menu/sub-menu.vue";
import videoProduct from "../components/video/video.vue";
import newProduct from "../components/news/news.vue";
import ecosystemList from "../components/ecosystemList/ecosystemList.vue";
import partner from "../components/partner/partner.vue";
import { getCatory } from "@/api/engineering";
import LoginForm from "@/components/login-form";
export default {
  data() {
    return {
      childIndex: 0,
      isActive: 0,
      activeTab: "ecosystem",
      showLoginForm: false,
      companyList: [],
      videoUrlList: [],
      newsList: [],
      navActive: 0,
      menuList: [
        { label: "首页", name: "home", url: "" },
        { label: "应用系统", name: "ecosystem", url: "" },
        { label: "行业资讯", name: "news", url: "" },
        { label: "荣誉证书", name: "books", url: "" },
        { label: "战略合作", name: "partner", url: "" },
        { label: "联系我们", name: "about", url: "" },
      ],
      leftMenuList: [],
      rightList: [],
      serviceList: [],
      currentIndex: 0,
      intervalId: null,
      activeIconList: [
        "https://oss.yanxin7.cn/2023-09-04/046157cf5d6cb56a68dc570060d73ffe",
        "https://oss.yanxin7.cn/2023-09-04/946d629b71ff7f9fae3f8739de62c43e",
        "https://oss.yanxin7.cn/2023-09-04/e2353a04baf12b4bdc6c8abee5303c4d",
        "https://oss.yanxin7.cn/2023-09-04/5e12037f7b72f2b0bbb7349858b77157",
      ],
    };
  },

  components: {
    LoginForm,
    subMenu,
    videoProduct,
    newProduct,
    ecosystemList,
    partner,
  },
  mounted() {
    document.title = "应用系统";
    this.isActive = this.$route.query.params || 0
    this.getCatory();
  },
  methods: {
    async getCatory() {
      try {
        const { data } = await getCatory({ appid: "pcd87133b3134a7dff" });
        this.leftMenuList = data.filter((item) => item.type == "ecosystem");
        if (this.leftMenuList) {
          this.rightList = this.leftMenuList[this.isActive].child 
        }
      } catch (error) {
        console.log("ee", error);
      }
    },

    toPath(url) {
      console.log("url", url);

      let vRouter = this.$router.resolve({
        path: url,
        params: {},
      });
      console.log("vRouter", vRouter);
      window.open(vRouter.href, "_blank");
    },

    handNavItem(item, index) {
      this.navActive = index;
    },

    handItem(item, index) {
      this.isActive = index;
      this.rightList = item.child;
    },
    handleMouseOver(e) {
      this.childIndex = e;
    },
    handleMouseOut() {
      this.childIndex = 999;
    },
  },
};
</script>



<style scoped lang="less">
.flex-between {
  display: flex;
  justify-content: space-between;
}
.main-box {
  padding-top: 30px;
  box-sizing: border-box;
  width: 1520px;
  margin-left: 50%;
  transform: translateX(-50%);
}
::v-deep
  .nav-width
  #content.el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item.is-active {
  background-color: white !important;
  color: #007ef2 !important;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.nav-item {
  margin-right: 60px;
  width: 335px;
  height: 120px;
  border-radius: 0px 0px 0px 0px;
  padding: 20px 0;
  color: #8592a2;
  cursor: pointer;
}
.nav-item:last-child {
  margin-right: 0px;
}
.right-content {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 60px;
  margin-top: 60px;
}
.child-item:nth-child(5n) {
  margin-right: 0px;
}
.child-item {
  width: 280px;
  height: 275px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #eff1f6;
  padding: 20px;
  text-align: start;
  margin-right: 20px;
  cursor: pointer;
}
.top-tip {
  width: 30px;
  height: 4px;
  background: #2288fc;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.a-line1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mb-30 {
  margin-bottom: 30px;
}
.fz-24 {
  font-size: 24px;
}
.b-line {
  margin: 30px 0;
  border-bottom: 1px solid rgb(224, 221, 221);
}
.a-mb60 {
  margin-bottom: 60px;
}

.is_active {
  color: #2288fc;
  width: 335px;
  height: 120px;
  background: #f3f9ff;
  border-radius: 0px 0px 0px 0px;
  border-bottom: 2px solid #2288fc;
}
.header-title {
  color: #212121;
  font-size: 32px;
  margin-top: 40px;
  margin-bottom: 40px;
}
::v-deep  .el-button {
  color: #007ef2;
}
.child-item:hover {
  width: 280px;
  height: 275px;
  background: #ffffff;
  box-shadow: 3px 3px 16px 1px rgba(61, 145, 255, 0.16);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #eff2f8;
}
.child-item:hover::v-deep  .el-button{
  background-color: #2288fc;
  color: #fff;
}
</style>
